<template>
  <v-row>
    <v-col cols="12">
      <general-form :customer="customer" />
    </v-col>

    <!-- Agency Information -->
    <v-col
      cols="12"
      md="6"
    >
      <agency :customer="customer" />
    </v-col>

    <!-- Opportunity Card -->
    <v-col
      v-if="customer.user"
      cols="12"
      md="6"
    >
      <opportunities :customer="customer" />
    </v-col>

    <!-- Important Dates Card -->
    <v-col
      v-else
      cols="12"
      md="6"
    >
      <important-dates :policies="customer.policies" />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    components: {
      Agency: () => import('./Agency'),
      ImportantDates: () => import('./ImportantDates'),
      Opportunities: () => import('./Opportunities'),
      GeneralForm: () => import('./GeneralForm'),
    },

    props: {
      customer: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
